import React from 'react';
import { FaFly } from 'react-icons/fa';
import { BiBuildings } from 'react-icons/bi';
import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import CardTitle from 'reactstrap/lib/CardTitle';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Dropdown from 'reactstrap/lib/Dropdown';
import { useState } from 'react';
import Modal from 'reactstrap/lib/Modal';
import $ from 'jquery';

const modalContent = {
  'Business Class':
    'Before choosing for a Business Class ticket, you should well define your really needs, that can stand for an in front seat, a larger seat, a larger space for your legs or maybe you choose to be spoiled by the crew. Our professional advice is that most of this features that can help to make your trip more comfortable are available in economical class for an affordable price or by joining the frequent flyer programs of the airlines. We will help you to identify the cheapest way to get what is important for you during a flight.',
  Sports:
    'Even you are a sport organization or a sport player traveling to a sport event, we can take care of your specific needs as, time of travel that will assure you a good rest before the competition, a more comfortable seat during the trip, extra baggage and sport equipment allowance, discounted rates for frequent flyers, group rates in case we talk about a sport team or a group of supporters.',
  Events:
    'Artists and bands do have to travel as many as possible to promote their work. Such trips require a certain privacy and also to move their instruments and technic all over the world. Even it is about one instrument of the equipment of an orchestra or of a band, we can get the best deals for you.',
  Mice:
    'Your company might be the host of an international event or should attend an international event as a guest, we can take care of the transport and the accommodation worldwide and get the best deals from the airlines and hotel chains.',
  'Last Minute Trips':
    'On some popular destinations, the airlines are providing many flights with various schedules. Some departure and arrival times are less popular and usually, the seats offered are not totally sold. With some chance and with our support, you can get extremely low prices even in the high season and your dream destination could be reached for a little budget.',
  Family:
    'Going out for holiday with your family is so important as the time dedicated to your business or your job. We will find for you the most convenient way to travel to your destination while enjoying precious time with your family and your friends. The well-deserved holiday is even more glamorous if you spent an affordable budget for transport, hotel, and local entertainment. Through our network of international partners, we will assist you on all continents.',
  Groups:
    'Airlines and hotels are more than happy to receive clients in groups as it assures them good cashflows. Your request for a trip in-group will receive discounted prices from the airlines and from the hotels. Our experts will take the charge to leverage the offer of these suppliers to fit your budget.',
  'City-Breaks':
    'Short holidays and city-breaks are quite popular and a way to breath from the crowded and suffocated office time. These types of holidays should be the essence of good time and vibes. We will help you to reach the best destination according with your mood for a more cultural or relaxing city.',
  SME:
    'If you are part of a SME company, you are in the middle of the driving forces of the economy. These companies are the blood of the economical process as money is the blood of the economy. Many programs are sustaining the activity of such companies and the transport providers are paying special attention to the SMEs particular needs. We will help you to harmonize in an efficient manner your budget spent on transport by analyzing the offer of the appropriate suppliers and negotiating with them in your best interest. You are valuable for us and for the suppliers so we will work hand in hand in your interest.',
  Corporate:
    'You are already a major actor in the economy, and you have the power to negotiate the best deals. If you do not have an integrated travel department you might need external support from professionals. Big companies are a valuable source of revenue for transport providers mainly for the airlines. Most of the Business Class passengers are provided by the corporates and the same stands for the frequent flyer passengers that are crowding the aircrafts every week. With our support, you will optimize the travel expenditure while your staff will benefit from the best travel conditions. There are various ways to accomplish such deals and we will use all our know-how resources to get it for you.',
};

function CustomProjects() {
  const [modal, setModal] = useState([false, '']);

  return (
    <div className="section section-project cd-section pb-0" id="projects">
      <div className="projects-1">
        <Container>
          <Row>
            <Col md="5">
              <Card
                data-background="image"
                style={{
                  backgroundImage:
                    'url(' +
                    require('assets/img/sections/pavel-kosov.jpg') +
                    ')',
                }}
              >
                <CardBody style={{ maxWidth: '600px' }}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <CardTitle tag="h2" className="font-weight-bolder">
                      BIZ TRIPS TIPS
                    </CardTitle>
                  </a>
                  {/* <p className="card-description font-weight-bold">
                    Redefining travel experiences has reached a new horizon with
                    mind-blowing sophistication and unimaginable luxurie.
                  </p> */}
                  <br />
                  <UncontrolledDropdown direction="up">
                    <DropdownToggle className="btn-round btn-danger" caret>
                      <BiBuildings className="mr-1" />
                      BIZ TRIPS TIPS
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        onClick={() => setModal([true, 'Business Class'])}
                      >
                        Business Class
                      </DropdownItem>
                      <DropdownItem onClick={() => setModal([true, 'Sports'])}>
                        Sports
                      </DropdownItem>
                      <DropdownItem onClick={() => setModal([true, 'Events'])}>
                        Events
                      </DropdownItem>
                      <DropdownItem onClick={() => setModal([true, 'Mice'])}>
                        Mice
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardBody>
              </Card>
            </Col>
            <Col md="7">
              <Card
                data-background="image"
                style={{
                  backgroundImage:
                    'url(' +
                    require('assets/img/sections/gukhwa-jang.jpg') +
                    ')',
                }}
              >
                <CardBody style={{ maxWidth: '600px' }}>
                  <CardTitle tag="h2" className="font-weight-bolder w-100">
                    LEASURE TRIPS HITS
                  </CardTitle>
                  {/* <p className="card-description font-weight-bold">
                    Adventure Tourism is a term which is not easily defined.
                    Different people have different perceptions of what might
                    constitute “adventure”.
                  </p> */}
                  <br />
                  <UncontrolledDropdown direction="up">
                    <DropdownToggle className="btn-round btn-primary" caret>
                      <FaFly className="mr-1" /> LEASURE TRIPS HITS
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => setModal([true, 'Last Minute Trips'])}
                      >
                        Last Minute
                      </DropdownItem>
                      <DropdownItem onClick={() => setModal([true, 'Family'])}>
                        Family
                      </DropdownItem>
                      <DropdownItem onClick={() => setModal([true, 'Groups'])}>
                        Groups
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setModal([true, 'City-Breaks'])}
                      >
                        City-Breaks
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card
                data-background="image"
                style={{
                  backgroundImage:
                    'url(' +
                    require('assets/img/sections/joshua-stannard.jpg') +
                    ')',
                }}
              >
                <CardBody style={{ maxWidth: '600px' }}>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <CardTitle tag="h2">
                      <b>MANAGE THE TRAVEL BUDGET</b>
                    </CardTitle>
                  </a>
                  {/* <p className="card-description font-weight-bold">
                    Tourism involving residents of one country traveling only
                    within that country.
                  </p> */}
                  <br />
                  <UncontrolledDropdown direction="up">
                    <DropdownToggle className="btn-round btn-info" caret>
                      <i className="fa fa-book mr-1" />
                      MANAGE THE TRAVEL BUDGET
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => setModal([true, 'SME'])}>
                        SME
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => setModal([true, 'Corporate'])}
                      >
                        {' '}
                        Corporate
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* MODALS */}
      <Modal isOpen={modal[0]} toggle={() => setModal([false, ''])}>
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => setModal([false, ''])}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center font-weight-bold"
            id="exampleModalLabel"
          >
            {modal[1]}
          </h5>
        </div>
        <div
          className="modal-body font-weight-bold"
          dangerouslySetInnerHTML={{ __html: modalContent[modal[1]] }}
        ></div>
        <div className="modal-footer">
          <Button
            className="btn-link"
            color="default"
            type="button"
            onClick={() => {
              setModal([false, '']);
              document
                .getElementById('contact-us')
                .scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          >
            Okay
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default CustomProjects;
