/*!

=========================================================
* Paper Kit PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch,
  HashRouter,
} from 'react-router-dom';

// styles
import 'assets/css/bootstrap.min.css';
import 'assets/scss/paper-kit.scss';
import 'assets/demo/demo.css';
import 'assets/demo/react-demo.css';
// pages
import Index from 'views/Index.js';
import NucleoIcons from 'views/NucleoIcons.js';
import Sections from 'views/Sections.js';
import Presentation from 'views/Presentation.js';
import AboutUs from 'views/examples/AboutUs.js';
import AddProduct from 'views/examples/AddProduct.js';
import BlogPost from 'views/examples/BlogPost.js';
import BlogPosts from 'views/examples/BlogPosts.js';
import ContactUs from 'costum_components/CustomContact.js';
import Discover from 'views/examples/Discover.js';
import Ecommerce from 'views/examples/Ecommerce.js';
import Error404 from 'views/examples/Error404.js';
import Error422 from 'views/examples/Error422.js';
import Error500 from 'views/examples/Error500.js';
import LandingPage from 'views/examples/LandingPage.js';
import LoginPage from 'views/examples/LoginPage.js';
import ProductPage from 'views/examples/ProductPage.js';
import ProfilePage from 'views/examples/ProfilePage.js';
import RegisterPage from 'views/examples/RegisterPage.js';
import SearchWithSidebar from 'views/examples/SearchWithSidebar.js';
import Settings from 'views/examples/Settings.js';
import TwitterRedesign from 'views/examples/TwitterRedesign.js';
import CustomContactForm from 'costum_components/CustomContactForm';
import CustomContact from 'costum_components/CustomContact';
// others

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Presentation {...props} />} />
      <Route
        path="/contact-us"
        render={(props) => <CustomContact {...props} />}
      />
      <Redirect to="/4Business4You" />
    </Switch>
  </HashRouter>,
  document.getElementById('root')
);
