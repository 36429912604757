import React from 'react';
import { Link } from 'react-router-dom';
// nodejs library that concatenates strings
import classnames from 'classnames';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from 'reactstrap';
import Modal from 'reactstrap/lib/Modal';
import { useState } from 'react';
// core components

const modalContent = {
  Mission: 'Make your travel a never ending pleasant experience.',
  Vision:
    'May be the SKY is the limit but you can always try to reach the horizon.',
  Objectives:
    'Your trip starts in your mind and becomes real through our touch. We will know from the beginning how will be your road but we will know only after how was your experience. We wish to know our clients and to plan together experiences not only trips.',
};

function CustomNavbar({ transparentTop = false }) {
  const [modal, setModal] = useState([false, '', false]);

  const goToProjects = () =>
    document
      .getElementById('projects')
      .scrollIntoView({ behavior: 'smooth', block: 'center' });
  const goToServices = () =>
    document
      .getElementById('services')
      .scrollIntoView({ behavior: 'smooth', block: 'center' });

  const [navbarColor, setNavbarColor] = React.useState(
    transparentTop ? 'navbar-transparent' : ''
  );
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor('');
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        if (transparentTop) setNavbarColor('navbar-transparent');
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames('fixed-top', navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            <UncontrolledDropdown>
              <DropdownToggle nav>
                <NavbarBrand
                  id="navbar-brand"
                  tag={Link}
                  onClick={(e) => {
                    e.preventDefault();
                    if (window.location.hash !== '/')
                      window.location.hash = '/';
                  }}
                >
                  4BIZ-4YOU Travel
                </NavbarBrand>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setModal([true, 'Mission', false])}
                >
                  Mission
                </DropdownItem>
                <DropdownItem onClick={() => setModal([true, 'Vision', false])}>
                  Vision
                </DropdownItem>
                <DropdownItem
                  onClick={() => setModal([true, 'Objectives', false])}
                >
                  Objectives
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>{' '}
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  color="default"
                  nav
                  onClick={() => {
                    if (window.location.hash !== '/')
                      window.location.hash = '/';
                  }}
                >
                  Home
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem onClick={goToProjects}>
                    Biz Trips Tips
                  </DropdownItem>
                  <DropdownItem onClick={goToProjects}>
                    Leasure Trips Hits
                  </DropdownItem>
                  <DropdownItem onClick={goToProjects}>
                    Manage the Travel Budget
                  </DropdownItem>
                  <DropdownItem onClick={goToServices}>
                    Our Services
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  color="default"
                  nav
                  onClick={() => (window.location.hash = '/contact-us')}
                >
                  Contact Us
                </DropdownToggle>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {/* MODALS */}
      <Modal isOpen={modal[0]} toggle={() => setModal([false, ''])}>
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => setModal([false, ''])}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center font-weight-bold"
            id="exampleModalLabel"
          >
            {modal[1]}
          </h5>
        </div>
        <div
          className="modal-body font-weight-bold"
          dangerouslySetInnerHTML={{ __html: modalContent[modal[1]] }}
        ></div>
        {/* <div className="modal-footer">
          <Button
            className="btn-link"
            color="default"
            type="button"
            onClick={() => {
              setModal([false, '']);
            }}
          >
            Okay
          </Button>
        </div> */}
      </Modal>
    </>
  );
}

export default CustomNavbar;
