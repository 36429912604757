import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap';

// core components

function CustomDestinations() {
  return (
    <div className="section section-gray cd-section pt-2 pb-5" id="blogs">
      <div className="blog-4">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title text-center">Top Destinations</h2>
              <br />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card className="card-plain card-blog text-center">
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img img-raised"
                      src={require('assets/img/sections/val-vesa.jpg')}
                    />
                  </a>
                </div>
                <CardBody>
                  <CardTitle tag="h3">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Some where over the rainbow
                    </a>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-plain card-blog text-center">
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img img-raised"
                      src={require('assets/img/sections/rodrigo-ardilha.jpg')}
                    />
                  </a>
                </div>
                <CardBody>
                  <CardTitle tag="h3">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Some where else
                    </a>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Card className="card-plain card-blog text-center">
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img img-raised"
                      src={require('assets/img/sections/federico-beccari.jpg')}
                    />
                  </a>
                </div>
                <CardBody>
                  <CardTitle tag="h3">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Probably not in Europe
                    </a>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-plain card-blog text-center">
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img img-raised"
                      src={require('assets/img/sections/pedro-lastra.jpg')}
                    />
                  </a>
                </div>
                <CardBody>
                  <CardTitle tag="h3">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Brussels!
                    </a>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <br />
      <br />
    </div>
  );
}

export default CustomDestinations;
