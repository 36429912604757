import React from 'react';
import { useState } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import Select from 'react-select';
import InputGroup from 'reactstrap/lib/InputGroup';
import ReactDatetimeClass from 'react-datetime';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InputGroupText from 'reactstrap/lib/InputGroupText';

const selectOptions = [
  { value: '', label: ' Choose city', isDisabled: true },
  { value: '1', label: 'Paris ' },
  { value: '2', label: 'Bucharest' },
  { value: '3', label: 'Rome' },
  { value: '4', label: 'New York' },
  { value: '5', label: 'Miami ' },
  { value: '6', label: 'Piatra Neamt' },
  { value: '7', label: 'Paris ' },
  { value: '8', label: 'Bucharest' },
  { value: '9', label: 'Rome' },
  { value: '10', label: 'New York' },
  { value: '11', label: 'Miami ' },
];

function CustomHeader() {
  const [defaultSelect, setDefaultSelect] = React.useState(null);

  return (
    <div className="wrapper">
      <div
        className="page-header"
        style={{
          backgroundImage:
            'url(' + require('assets/img/sections/header-2.jpg') + ')',
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="9">
                <h1 className="title">Get Travel Best Fares</h1>
                <h5 className="description">
                  We help you travel in a wisely manner!
                </h5>
                <br />
              </Col>
              <Col className="ml-auto mr-auto" md="11">
                <Card className="card-raised card-form-horizontal no-transition">
                  <CardBody>
                    <Form action="" method="">
                      <Row>
                        <Col md="3">
                          <FormGroup>
                            <Select
                              className="react-select react-select-default"
                              classNamePrefix="react-select"
                              name="defaultSelect"
                              value={defaultSelect}
                              onChange={(value) => setDefaultSelect(value)}
                              options={selectOptions}
                              placeholder="FROM"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <Select
                              className="react-select react-select-default"
                              classNamePrefix="react-select"
                              name="defaultSelect"
                              value={defaultSelect}
                              onChange={(value) => setDefaultSelect(value)}
                              options={selectOptions}
                              placeholder="TO"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <InputGroup className="date" id="datetimepicker">
                              <ReactDatetimeClass
                                inputProps={{
                                  className: 'form-control',
                                  placeholder: 'Date',
                                }}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <span className="glyphicon glyphicon-calendar">
                                    <i className="fa fa-calendar" />
                                  </span>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup>
                            <Input
                              defaultValue=""
                              placeholder="Number"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <Button block color="danger" type="button">
                            <i className="nc-icon nc-zoom-split" /> Search
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default CustomHeader;
