import React from 'react';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';

function CustomFooter() {
  return (
    <footer className="footer footer-black footer-big">
      <Container>
        <Row>
          <Col className="text-center ml-auto mr-auto" md="2" sm="3" xs="12">
            <h4>
              4BIZ 4YOU <br />
              Travel
            </h4>
            <div className="social-area">
              <Button className="btn-just-icon btn-round mr-1" color="facebook">
                <i className="fa fa-facebook" />
              </Button>
              <Button className="btn-just-icon btn-round mr-1" color="twitter">
                <i className="fa fa-twitter" />
              </Button>
              <Button className="btn-just-icon btn-round" color="google">
                <i className="fa fa-google-plus" />
              </Button>
            </div>
          </Col>
          <Col className="ml-auto mr-auto" md="8" sm="8" xs="12">
            <Row>
              <Col md="3" sm="3" xs="6">
                <div className="links">
                  <ul className="uppercase-links stacked-links">
                    <li>
                      <a href="#" onClick={(e) => (window.location.hash = '/')}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/#/contact-us">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="3" sm="3" xs="6">
                <div className="links">
                  <ul className="uppercase-links stacked-links">
                    <li>
                      {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        About Us
                      </a> */}
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md="6" sm="6" xs="6">
                <p>
                  Schiphol Boulevard 127, <br />
                  1118BG Schiphol, <br />
                  Netherlands
                </p>
                <hr />
                <p>
                  +31 970 102 804 28 <br />
                  procurement@4business4you.nl
                </p>
              </Col>
            </Row>
            <hr />
            <div className="copyright">
              <div className="pull-left">
                © {new Date().getFullYear()}, 4 Business 4 You Travel
              </div>
              <div className="links pull-right">
                <ul>
                  <li className="mr-1">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Company Policy
                    </a>
                  </li>
                  |{' '}
                  <li className="mr-1">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Terms
                    </a>
                  </li>
                  |{' '}
                  <li>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Privacy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default CustomFooter;
