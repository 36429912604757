import React from 'react';
import { useState } from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
} from 'reactstrap';
import Modal from 'reactstrap/lib/Modal';

// core components

const modalContent = {
  'Save Time':
    'Our experts always have time for you. Get in contact with us for the best travel advices based on our valuable experience and the last-minute information about travelling.',
  'Save Money':
    'Taking the best decision about the moment to travel, the airline to choose, the airport to use, the baggage to carry, could save you money and make your travel more pleasant. Our travel experts will help you to make the optimal decision about your trip.',
  'Smart Solutions':
    'Based on our know-how we can share with you plenty of tricks from the travel industry that can help to optimize your travel experience.',
};

function CustomServices() {
  const [modal, setModal] = useState([false, '']);

  return (
    <div className="section section-dark cd-section py-0" id="services">
      <div
        className="features-5 section-image"
        style={{
          backgroundImage:
            'url(' +
            require('assets/img/sections/the-how-photographer.jpg') +
            ')',
        }}
      >
        <Container>
          <Row>
            <div className="ml-auto mr-auto text-center mt-0">
              <h2 className="title text-center mb-2 py-0">Our Services</h2>
              <h5 className="description">
                Some of the reasons you are here and not only
              </h5>
            </div>
          </Row>
          <Row className="bottom-line">
            <Col
              className="ml-auto"
              sm="3"
              onClick={() => setModal([true, 'Save Time'])}
              style={{ cursor: 'pointer' }}
            >
              <div className="info">
                <div className="icon">
                  <i aria-hidden={true} className="nc-icon nc-time-alarm" />
                </div>
                <h4 className="title">Save Time</h4>
              </div>
            </Col>
            <Col
              className=""
              sm="3"
              onClick={() => setModal([true, 'Save Money'])}
              style={{ cursor: 'pointer' }}
            >
              <div className="info">
                <div className="icon">
                  <i aria-hidden={true} className="nc-icon nc-credit-card" />
                </div>
                <h4 className="title">Save Money</h4>
              </div>
            </Col>
            <Col
              className="mr-auto"
              sm="3"
              onClick={() => setModal([true, 'Smart Solutions'])}
              style={{ cursor: 'pointer' }}
            >
              <div className="info">
                <div className="icon">
                  <i aria-hidden={true} className="nc-icon nc-paper" />
                </div>
                <h4 className="title">Smart Solutions</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* MODALS */}
      <Modal isOpen={modal[0]} toggle={() => setModal([false, ''])}>
        <div className="modal-header">
          <button
            className="close"
            type="button"
            onClick={() => setModal([false, ''])}
          >
            <span>×</span>
          </button>
          <h5
            className="modal-title text-center font-weight-bold"
            id="exampleModalLabel"
          >
            {modal[1]}
          </h5>
        </div>
        <div
          className="modal-body font-weight-bold"
          dangerouslySetInnerHTML={{ __html: modalContent[modal[1]] }}
        ></div>
        <div className="modal-footer">
          <Button
            className="btn-link"
            color="default"
            type="button"
            onClick={() => {
              setModal([false, '']);
              document
                .getElementById('contact-us')
                .scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          >
            Okay
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default CustomServices;
