import React from 'react';

// core components
import InfoNavbar from 'components/Navbars/InfoNavbar.js';
import FooterWhite from 'components/Footers/FooterWhite.js';
import CustomNavbar from './CustomNavbar';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import CustomFooter from './CustomFooter';
import CustomContactForm from './CustomContactForm';

function CustomContact() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('contact-page');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('contact-page');
    };
  });
  return (
    <>
      <CustomNavbar />
      <CustomContactForm />
      <CustomFooter />
    </>
  );
}

export default CustomContact;
